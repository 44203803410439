import jwtDefaultConfig from './jwtDefaultConfig'
//import axios from 'axios'
import axios from '@src/configs/axios'
import toast from 'react-hot-toast'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
       // ** Define partial URLs where no loading indicator should appear
    const excludeLoadingUrls = ['v1/meta/audiosave'];
    // ** Request Interceptor
    axios.interceptors.request.use(
     
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken();
        // ** Add loading indicator if the URL is not in the exclude list
        if (!excludeLoadingUrls.some(url => config.url.includes(url))) {
          document.body.classList.add('loading-indicator');
        }

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => {
        document.body.classList.remove('loading-indicator');
            // ** Prevent showing success messages for excluded URLs
      if (!excludeLoadingUrls.some(url => response.config.url.includes(url))) {
      let message = response.message;
      if (response?.status === 200 && (response?.config?.method === "post" || response?.config?.method === "put")) {
        message = response.data.message;
        if (message) {
          toast.success(message, { duration: 2000 });
        }
      }
    }
        return response
      
      },
      error => {
        document.body.classList.remove('loading-indicator');
        const { config, response } = error
        const originalRequest = config 
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            location.href = '/login'
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
          let message = response?.message;
          //if (response?.status === 403)  message = "You are not authorized to perform this action.";
          if (response?.status === 400 || response?.status === 422)  message = response.data.message;
          if (message) {
          toast.error(message, {duration: 2000})
          }
        return Promise.reject(error)  
      }

    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  getCampaignDetails(...args) {
    return axios.get(this.jwtConfig.getCampaign, ...args)
  }

  getOrganisation(...args) {
    return axios.get(this.jwtConfig.getOrganisation, ...args)
  }

  getCampaignFilters(...args) {
    return axios.get(this.jwtConfig.getCampaignFilters, ...args)
  }

  googleLogin(...args) {
    return axios.post(this.jwtConfig.googleLoginEndpoint, ...args)
  }

  microsoftLogin(...args) {
    return axios.post(this.jwtConfig.microsoftLoginEndpoint, ...args)
  }

  googleSignup(...args) {
    return axios.post(this.jwtConfig.googleSignupEndpoint, ...args)
  }

  microsoftSignup(...args) {
    return axios.post(this.jwtConfig.microsoftSignupEndpoint, ...args)
  }


  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  updateOrganisation(...args) {
    return axios.put(this.jwtConfig.updateOrganisation, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }
}
